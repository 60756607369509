import React from 'react'
import {Route, Routes} from 'react-router-dom'
import ULink from "./uLink"
import './App.css'

function App() {
    return <Routes>
        <Route path="/*" element={<ULink />} />
    </Routes>
}

export default App
